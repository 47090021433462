import {IContent} from "../../HubFramework"
import AllModules from "./Tabs/AllModules";
import NewModuleInstances from "./Tabs/NewModuleInstances";
import ProjectInformation from "./Tabs/ProjectInformation";
import ProjectDocumentation from "./Tabs/ProjectDocumentation";
import {UserRoles} from "../../Utils";
import ProjectUserManagement from "./Tabs/UserManagement";
import {Logs} from "./Tabs/Logs";
import ProjectApiKeyManagement from "./Tabs/ApiKeyManagement";

/**
 * The content of the module
 * This is mandatory for every module. It contains all the information about the module.
 */
export const moduleContent: IContent = {
    moduleName: "ProjectContent",
    title: "Project",
    pages: [
        {
            displayName: "My Modules",
            contentPage: AllModules
        },
        {
            displayName: "Add module",
            contentPage: NewModuleInstances,
            minimumRole: UserRoles.editor
        },
        {
            displayName: "Users & Permissions",
            contentPage: ProjectUserManagement
        },
        {
            displayName: "API Keys",
            contentPage: ProjectApiKeyManagement,
            minimumRole: UserRoles.admin
        },
        {
            displayName: "Logs",
            contentPage: Logs
        },
        {
            displayName: "Project Information",
            contentPage: ProjectInformation
        },
        {
            displayName: "Help",
            contentPage: ProjectDocumentation
        }
    ],
    defaultPage: "My Modules",
};
