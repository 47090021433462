import * as React from "react";
import {HubPathRouting} from "../../../HubFramework/pathBuilder";
import {IListStaticModule} from "../../../BackendFacade/IStaticModules";
import {IModule} from "../../../BackendFacade/IModuleInstances";
import {listModuleInstances, listStaticModules} from "../../../BackendFacade/moduleCalls";
import {ILogs} from "../Interfaces/ILogs";
import KafkaEventsPage from "../../DAMS/Tabs/KafkaEvents";
import HubFunctions from "../../../HubFramework/hubFunctions";

export class Logs extends React.Component<{}, ILogs> {
    constructor(props: any) {
        super(props);
        this.state = {
            kafkaAvailable: false,
        }
    }

    async componentDidMount() {
        HubFunctions.showLoading()
        if(await this.evaluateDamsAvailablity()) {
            this.setState({kafkaAvailable: true})
        }
        HubFunctions.hideLoading()
    }

    async evaluateDamsAvailablity() {
        // get all static modules for the id of eventLogTopicName
        let staticModules: IListStaticModule[] = []
        let prom1 = listStaticModules().then((data) => {
            if(data.success) {
                staticModules = data.data.value
            }
        })

        // get all module instances for the linked id of kafkaTopic
        let moduleInstances: IModule[] = []
        let prom2 = listModuleInstances(HubPathRouting.currentProject.moduleInstanceId).then((data) => {
            if(data.success) {
                moduleInstances = data.data.value
            }
        })

        await Promise.all([prom1, prom2])

        // find the intersection of both
        return moduleInstances.some((module) => {
            const staticModule = staticModules.find((staticModule) => staticModule.staticModuleId === module.staticModuleId);
            return !!staticModule.eventLogTopicName;
        });
    }

    public render() {
        return <>
            {this.state.kafkaAvailable ?
                <KafkaEventsPage/>
            :
            <div>no logs available</div>
            }

        </>
    }
}
